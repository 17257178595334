import React, { Component, lazy, Suspense } from 'react';
import { detectBot } from 'lib/seo.utils';
import Routes from './routes';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import withAuthentication from 'hoc/withAuthentication';
const GoogleTagManager = lazy(() =>
    import('components/TagManager/GoogleTagManager')
);

const pathNameTrackingExclusionList = [
    '/estimation/9', // The valuation results are tracked manually to add properties Category, IsPro and NbShowcases
    '/marche-immobilier/', // Market explorer is tracked manually to add geoLevel
    '/agences-et-mandataires-immobiliers/', // Agent directory is tracked manually to add geoLevel
];
class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPathname: '',
        };

        this.setViewHeight();

        window.addEventListener('resize', () => {
            this.setViewHeight();
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.currentPathname !== nextProps.location.pathname;
    }

    componentDidUpdate() {
        const { location } = this.props;
        const { currentPathname } = this.state;

        if (location.pathname !== currentPathname) {
            this.setState({ currentPathname: location.pathname });

            //We check the currentPathName doesn't contains any terms in the exclusion List
            if (
                !pathNameTrackingExclusionList.some(exclusion =>
                    location.pathname.includes(exclusion)
                )
            ) {
                if (window.posthog) window.posthog.capture('$pageview');
            }
        }
    }

    // To handle viewport issue in ios
    setViewHeight = () => {
        const vh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    render() {
        const isBot = detectBot();
        return (
            <>
                <div className="min-h-[calc(var(--vh))]">
                    <Header {...this.props} />
                    <Routes />
                </div>
                <Footer />

                {!isBot ? (
                    <Suspense fallback={null}>
                        <GoogleTagManager gtmId="GTM-KCVCKBK" />
                    </Suspense>
                ) : null}
            </>
        );
    }
}

export default withAuthentication(App);
