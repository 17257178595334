import React, { Component } from 'react';
import { classNames } from 'lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const classes = {
    color: {
        gray: 'bg-gray-100 text-gray-700',
        red: 'bg-red-50 text-red-700',
        yellow: 'bg-yellow-50 text-yellow-700',
        green: 'bg-green-50 text-green-700',
        primary: 'bg-primary-50 text-primary-700',
        primaryInverted: 'bg-primary-500 text-white',
        custom: 'bg-custom-50 text-custom-700',
        pro: 'bg-pro-50 text-pro-700',
        proInverted: 'bg-pro-500 text-white',
    },
    padding: {
        base: 'px-2.5 py-0.5',
        lg: 'px-3 py-1.5',
    },
};

class Badge extends Component {
    render() {
        const { label, color, padding, icon, className, leftItem } = this.props;

        return (
            <>
                <span
                    className={classNames(
                        'inline-flex items-center rounded-full text-xs font-medium whitespace-nowrap',
                        classes.color[color],
                        classes.padding[padding],
                        className
                    )}
                >
                    {leftItem}
                    {label}
                    {icon && (
                        <FontAwesomeIcon
                            icon={icon}
                            className="pl-1.5 h-3 w-3"
                            aria-hidden="true"
                        />
                    )}
                </span>
            </>
        );
    }
}

Badge.defaultProps = {
    label: 'insert text',
    color: 'gray',
    padding: 'base',
    icon: null,
    className: '',
    leftItem: null,
};
export default Badge;
